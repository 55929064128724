var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.type
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Navn",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn på test type.';
      }],
      "required": ""
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Beskrivelse",
      "rules": [function (v) {
        return !!v || 'Fyll inn beskrivelse på test type.';
      }],
      "required": ""
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "label": _vm.data.isActive ? 'Aktiv' : 'Inaktiv'
    },
    model: {
      value: _vm.data.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "isActive", $$v);
      },
      expression: "data.isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }